<template>
  <div class="success-container">
    <div class="header">
      <router-link :to="{ name: 'home' }">
        <img src="@/assets/wifi-block-logo.svg" />
      </router-link>
    </div>

    <h1>Thank you for your order</h1>
    <h4>Please check your email for order updates</h4>

    <div>
      <img class="ordered-wifiblock" :src="stripeSession.display_items[0].custom.images[0]" />
    </div>

    <h2>Order Total: {{ stripeSession.display_items[0].amount / 100 }}</h2>
  </div>
</template>

<script>
export default {
  name: 'SuccessView',
  data() {
    return {
      session: {},
      stripeSession: {},
    };
  },
  mounted() {
    // Track the purchase
    if (window.fbq) {
      window.fbq('track', 'Purchase', {
        value: 39.99,
        currency: 'USD',
      });
    }
    if (window.rdt) {
      window.rdt('track', 'Purchase');
    }

    this.stripeSession = JSON.parse(localStorage.getItem('stripeSession'));
    localStorage.removeItem('design');
    localStorage.removeItem('stripeSession');

    this.$stripe.checkout.sessions.retrieve(this.$route.query.session_id, function(err, session) {
      if (err) {
        // console.log('Error Getting Session: ' + this.$route.query.session_id);
        return;
      }
      this.session = session;
    });
  },
};
</script>

<style lang="scss" scoped>
.success-container {
  min-height: 100%;

  .header {
    background: url('../assets/mobile-header.jpg');
    background-position: top left;
    background-size: cover;
    height: 125px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    margin-bottom: 28px;

    a {
      text-align: left;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
    }
    img {
      max-width: 40%;
    }
  }

  h1 {
    margin-bottom: 5px;
  }
  h4 {
    margin-top: 0px;
  }

  .ordered-wifiblock {
    max-width: 200px;
  }
}
</style>
